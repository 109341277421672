<template>
<div style="margin: 10px">
  <LayoutHeader title="Companii" :dontDistrurbFree="true"/>
  <div class="layoutBody">
    <div class="d-flex justify-start align-center flex-wrap ma-n1">
      
      <div class="ma-1" v-if="canAddCompany && canPerformOrganizationChanges">
        <AddButton v-if="canAddCompany && canPerformOrganizationChanges" :onClick="() => {openCompanyDialog()}" caption="Adauga" />
      </div>
      <div class="ma-1">
        <v-btn @click="exportCompanies()" class="text-gray-500 primary" :disabled="loading">
          <v-icon class="mr-1">
            mdi-cloud-download-outline
          </v-icon>
          <span>Exporta</span>
        </v-btn>
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="loading"
        ></v-progress-circular>
      </div>
     </div>
      <!-- :options.sync="options" -->
    <v-data-table :items="companies" :headers="companyHeaders" :footer-props="{
          itemsPerPageOptions: [25, 50, 100],
        }">
      <template v-slot:top>
        <v-row class="pt-4 pb-2">
          <v-col cols="12" md="6">
            <v-text-field v-model="search" label="Cauta ( Denumire / CUI )" clearable 
            @input="searchCompanies($event)" dense></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select clearable label="Filtreaza dupa status ACTIV" v-model="filters.isActive" 
              :items="['DA', 'NU']" color="primary" @change="filterCompanies" dense>
            </v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.workingPoints`]="props">
        {{props.item.workingPoints.filter(wp => !wp.isDeleted).length}}
      </template>
      <template v-slot:[`item.isActive`]="props">
          <p class="tableData">
          <div class="flex">
              <v-checkbox class="" style="align-items: center;" :input-value="props.item.isActive" :disabled="userDisabled || !canPerformOrganizationChanges"
              @click="updateCompanyActive(props.item)"></v-checkbox>
          </div>
          </p>
      </template>
      <template v-slot:[`item.createdAt`]="props">
        {{parseDate(props.item.createdAt)}}
      </template>
    </v-data-table>
  </div>
  <CreateWorkingPoint v-if="createDialog" :closeDialog="closeCreateDialog" :companies="companies" :companyOnly="true" :createCb="deleteFilters"/>
</div>
</template>
<script>
import moment from "moment-timezone";
import AddButton from '../components/Buttons/AddButton.vue';
import CreateWorkingPoint from '../components/Modals/Create/CreateWorkingPoint.vue';
import UpdateWorkingPoint from '../components/Modals/UpdateWorkingPoint.vue';
import SectionHeadline from '../components/Typography/SectionHeadline.vue';
import LayoutHeader from '../components/Widgets/LayoutHeader.vue';
export default {
  components: {
    LayoutHeader,
    SectionHeadline,
    AddButton,
    CreateWorkingPoint,
    UpdateWorkingPoint
  },
  data() {
    return {
      loading: false,
      search: '',
      filters: {
        isActive: null
      },
      createDialog: false,
      companyHeaders: [
        // {
        //   text: "Actiuni",
        //   value: 'actions'
        // },
        {
          text: "Denumire",
          value: 'companyName'
        },
        {
          text: "CUI",
          value: 'vatId'
        },
        {
          text: "Reg Com",
          value: 'regCom'
        },
        {
          text: "Judet",
          value: 'county'
        },
        {
          text: "Localitate",
          value: 'city'
        },
        {
          text: "Adresa",
          value: 'companyAddress'
        },
        {
          text: "Puncte de lucru",
          value: 'workingPoints'
        },
        {
          text: "Activ",
          value: 'isActive'
        },
        {
          text: "Data creare",
          value: 'createdAt'
        }
      ]
    };
  },
  watch: {
    // search(newVal) {
    //   this.timeout = setTimeout(() => {
    //     if(this.search == newVal) {
    //       this.searchCompanies()
    //     }
    //   }, 500);
    // },
  },
  computed: {
    companies() {
      return this.$store.state.companies.companies
    },
    // workingPoints() {
    //   return this.$store.state.workingPoints.workingPoints
    // },
    totalUsers() {
      return this.$store.state.companies.totalCompanies
    },
    user() {
      return this.$store.state.auth.user
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    userImp() {
      return this.$store.state.auth.userImp
    },
    canPerformOrganizationChanges() {
      return this.$store.state.auth.canPerformOrganizationChanges
    },

    userDisabled() {
      return this.$store.state.auth.userDisabled
    },
    userFree() {
      return this.$store.state.auth.userFree
    },
    userAdvanced() {
      return this.$store.state.auth.userAdvanced
    },
    totalWorkingPoints() {
      let total = 0
      this.companies.map(c => {
        total += c.workingPoints.filter(wp => !wp.isDeleted).length
      })
      return total
      // return this.workingPoints.length
    },
    
    allowedWorkingPoints() {
      if (this.userImp) {
        return this.userImp.allowedWorkingPoints
      }
      return this.user ? this.user.allowedWorkingPoints : 0
    },
    canAddCompany() {
      if(this.userAdvanced) {
        return this.totalWorkingPoints < this.allowedWorkingPoints
      }
      if(this.userFree) {
        // return this.totalWorkingPoints < 1
        //TRIAL HAS ALL FEATURES ENABLED
        return this.totalWorkingPoints < this.allowedWorkingPoints
      }
      return false
    },
    userName() {
      return this.userImp && this.userImp.name ? this.userImp.name : this.user.name
    }
  },
  methods: {
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    updateCompanyActive(company) {
      this.$store.dispatch("updateCompanyActive", {
        companyId: company._id,
      })
      company.isActive = !company.isActive
    },
    openCompanyDialog() {
      this.createDialog = true
    },
    closeCreateDialog() {
      this.createDialog = false
    },
    deleteFilters() {
      this.search = '';
      this.filters = {
        isActive: null
      }
    },
    // resetAllFilters() {
    //   this.search = '';
    //   this.filters = {
    //     isActive: null
    //   }
    //   this.filterCompanies()
    // },
    searchCompanies(event) {
      setTimeout(() => {
        if(this.search == event) {
          this.filterCompanies()
        }
      }, 500);
    },
    filterCompanies() {
      this.$store.dispatch('fetchCompanies', {
        search: this.search,
        filter: this.filters,
      });
    },
    exportCompanies() {
      this.loading = true;
      this.$store.dispatch("exportCompanies", {
        search: this.search,
        filter: this.filters,
        cb: (response) => {
          let fileName = "Companii.xlsx";
          this.loading = false;
          let fileUrl = window.URL.createObjectURL(response.data);
          let fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        },
      });
    },
  },
  created() {
    this.$store.dispatch('fetchCompanies', {
      search: this.search,
      filter: this.filters,
    });
  }
};
</script>

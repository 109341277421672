<template>
  <div style="margin: 10px">
    <section-headline> CRM </section-headline>
    <div class="flex">
      <v-spacer />
      <AddButton
        :onClick="
          () => {
            openCreateDialog();
          }
        "
        caption="Adauga"
        class="mx-2"
      />

      <v-btn @click="exportCRM()" class="text-gray-500 primary">
        <span>Exporta</span>
      </v-btn>
      <v-progress-circular
        indeterminate
        color="primary"
        v-if="loading"
      ></v-progress-circular>
    </div>
    <v-data-table
      :item-class="getRowClass"
      class="adminUsersTable"
      :items="crm"
      item-key="_id"
      :key="tableKey"
      :headers="userHeaders"
      :search="search"
      :options.sync="options"
      :server-items-length="totalCrm"
      :footer-props="{
        itemsPerPageOptions: [25, 50, 100],
      }"
    >
      <template v-slot:top>
        <div class="flex topSection">
          <v-col cols="12" md="3">
            <v-menu
              offset-y
              :close-on-content-click="false"
              v-model="createdFilter.show"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="createdFilter.filter"
                  label="Data creare"
                  clearable
                  hide-details
                  underlined
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearDateField(createdFilter)"
                ></v-text-field>
              </template>
              <v-card class="d-flex flex-column align-center bordered">
                <div class="d-flex justify-center">
                  <v-text-field
                    v-model="createdFilter.date1"
                    class="mr-5"
                    type="date"
                    label="Alege data start"
                  />
                  <v-text-field
                    v-model="createdFilter.date2"
                    type="date"
                    label="Alege data stop"
                  />
                </div>
                <v-btn
                  class="text-secondary"
                  @click="formatDateFilter(createdFilter)"
                >
                  OK
                </v-btn>
              </v-card>
            </v-menu>
          </v-col>

          <v-col cols="12" md="3">
            <v-menu
              offset-y
              :close-on-content-click="false"
              v-model="updatedFilter.show"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class=""
                  underlined
                  hide-details
                  color="primary"
                  v-model="updatedFilter.filter"
                  label="Data ultimul update"
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="clearDateField(updatedFilter)"
                ></v-text-field>
              </template>
              <v-card class="d-flex flex-column align-center bordered">
                <div class="d-flex justify-center">
                  <v-text-field
                    v-model="updatedFilter.date1"
                    class="mr-5"
                    type="date"
                    label="Alege data start"
                  />
                  <v-text-field
                    v-model="updatedFilter.date2"
                    type="date"
                    label="Alege data stop"
                  />
                </div>
                <v-btn
                  class="text-secondary"
                  @click="formatDateFilter(updatedFilter)"
                >
                  OK
                </v-btn>
              </v-card>
            </v-menu>
          </v-col>

          <v-col cols="12" md="3">
            <v-select
              clearable
              label="Ultimul status"
              v-model="statusFilter"
              density="compact"
              hide-details
              underlined
              :items="crmStatuses"
              color="primary"
            >
            </v-select>
          </v-col>

          <v-col cols="12" md="3">
            <v-select
              clearable
              label="Necesita follow-up"
              v-model="followUpFilter"
              density="compact"
              hide-details
              underlined
              :items="booleanItems"
              color="primary"
            >
            </v-select>
          </v-col>
        </div>

        <div class="flex">
          <v-col cols="12" md="6" class="pt-0">
            <v-text-field
              v-model="search"
              label="Cauta dupa nume, email, telefon, CUI, nume companie..."
              hide-details
            ></v-text-field>
          </v-col>
        </div>
      </template>

      <template v-slot:[`item.name`]="props">
        <div class="fill-in">
          <div v-if="!props.item.hasName" class="fill-in">
            <v-text-field
              type="text"
              placeholder="Completeaza"
              v-model="props.item.name"
              underlined
              @blur="saveItem(props.item)"
              @keydown.enter="saveItem(props.item)"
            />
          </div>

          <div v-else>{{ props.item.name }}</div>
        </div>
      </template>

      <template v-slot:[`item.phone`]="props">
        <div class="fill-in">
          <div v-if="!props.item.hasPhone" class="fill-in">
            <v-text-field
              type="text"
              placeholder="Completeaza"
              v-model="props.item.phone"
              underlined
              @blur="saveItem(props.item)"
              @keydown.enter="saveItem(props.item)"
            />
          </div>

          <div v-else>{{ props.item.phone }}</div>
        </div>
      </template>

      <template v-slot:[`item.email`]="props">
        <div class="fill-in">
          <div v-if="!props.item.hasEmail" class="fill-in">
            <v-text-field
              type="text"
              placeholder="Completeaza"
              v-model="props.item.email"
              underlined
              @blur="saveItem(props.item)"
              @keydown.enter="saveItem(props.item)"
            />
          </div>

          <div v-else>{{ props.item.email }}</div>
        </div>
      </template>

      <template v-slot:[`item.vatId`]="props">
        <div class="fill-in">
          <div v-if="!props.item.hasVatId" class="fill-in">
            <v-text-field
              type="text"
              placeholder="Completeaza"
              v-model="props.item.vatId"
              underlined
              @blur="saveItem(props.item)"
              @keydown.enter="saveItem(props.item)"
            />
          </div>

          <div v-else>{{ props.item.vatId }}</div>
        </div>
      </template>

      <template v-slot:[`item.status`]="props">
        <div class="fill-in">
          <div>
            <v-select
              v-model="props.item.status"
              underlined
              :items="crmStatuses"
              @change="saveItemStatus(props.item)"
            ></v-select>
          </div>
        </div>
      </template>

      <template v-slot:[`item.followUp`]="props">
        <div class="fill-in">
          <div v-if="!props.item.hasFollowUp" class="fill-in">
            <v-text-field
              v-model="props.item.followUp"
              type="date"
              persistent-placeholder
              label="Completeaza"
              underlined
              class="mt-2"
              @blur="saveItem(props.item)"
              @keydown.enter="saveItem(props.item)"
            />
          </div>

          <div v-else>{{ parseDate(props.item.followUp) }}</div>
        </div>
      </template>

      <template v-slot:[`item.createdAt`]="props">
        <div class="fill-in">
          {{ parseDate(props.item.createdAt) }} /
          {{ parseDate(props.item.updatedAt) }}
        </div>
      </template>

      <template v-slot:[`item.companyName`]="props">
        <div class="fill-in">
          {{ props.item.companyName }}
        </div>
      </template>

      <template v-slot:[`item.subject`]="props">
        <b class="fill-in">
          {{ props.item.subject }}
        </b>
      </template>

      <template v-slot:[`item.proforma`]="props">
        <div class="fill-in">
          <a
            v-if="props.item.linkProforma && props.item.seriesProforma"
            :href="props.item.linkProforma"
            target="_blank"
          >
            <div class="flex justify-start">
              {{ props.item.seriesProforma.toUpperCase() }}
              {{ props.item.noProforma }} /
              <div v-if="props.item.sentProforma">{{ "DA" }}</div>
              <div v-else>{{ "NU" }}</div>
            </div>
          </a>
        </div>
      </template>

      <template v-slot:[`item.createdBy`]="props">
        <div class="fill-in">
          {{ props.item.createdBy ? props.item.createdBy.name : "" }} /
          {{ props.item.updatedBy ? props.item.updatedBy.name : "" }}
        </div>
      </template>

      <template v-slot:[`item.observations`]="props">
        <div class="fill-in">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div class="observations-text" v-bind="attrs" v-on="on">
                {{ props.item.observations }}
              </div>
            </template>
            <div class="" style="width: 200px">
              {{ props.item.observations }}
            </div>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.actions`]="props">
        <div class="flex flex-column">
          <div class="flex">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="openLogDialog(props.item)"
                  icon
                  color="mixed"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-clipboard-plus-outline</v-icon>
                </v-btn>
              </template>
              <span>Adauga observatie</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="openHistoryDialog(props.item)"
                  icon
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-clipboard-text-clock-outline</v-icon>
                </v-btn>
              </template>
              <span>Istoric observatii</span>
            </v-tooltip>
            <v-tooltip top v-if="!loadingProforma[props.item._id]">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="showProforma(props.item)"
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-file-document-plus</v-icon>
                </v-btn>
              </template>
              <span>Genereaza proforma</span>
            </v-tooltip>
            <div v-else class="progress pl-2 pt-2">
              <v-progress-circular
                size="23"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </div>
          </div>

          <div class="flex">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="openCreateDialog(props.item)"
                  icon
                  color="turquoise"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-pen</v-icon>
                </v-btn>
              </template>
              <span>Editeaza</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="openDeleteDialog(props.item)"
                  icon
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
              </template>
              <span>Sterge</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="openSendProforma(props.item)"
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-email</v-icon>
                </v-btn>
              </template>
              <span>Trimite proforma</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </v-data-table>
    <AddLogDialog
      v-if="logDialog"
      :crm="editedUser"
      :getData="getData"
      :closeDialog="closeLogDialog"
    >
    </AddLogDialog>
    <DeleteDialog 
      v-if="deleteDialog" 
      :item="itemToDelete" 
      itemName="inregistrarea aferenta clientului" 
      :closeDialog="closeDeleteDialog"
      :deleteAction="deleteCRMItem" 
    />
    <SendProformaDialog
      v-if="sendProformaDialog"
      :crm="editedUser"
      :getData="getData"
      :closeDialog="closeSendProforma"
    />
    <HistoryDialog
      v-if="historyDialog"
      :crm="editedUser"
      :closeDialog="closeHistoryDialog"
    >
    </HistoryDialog>

    <CreateCRM
      v-if="createDialog"
      :closeDialog="closeCreateDialog"
      :editedItem="editedItem"
      :getTableData="getData"
    />
  </div>
</template>
<script>
import moment from "moment-timezone";
import AddButton from "../components/Buttons/AddButton.vue";
import AddLogDialog from "../components/Modals/AddCRMLogDialog.vue";
import DeleteDialog from '../components/Modals/DeleteDialog.vue';
import HistoryDialog from "../components/Modals/CRMHistoryDialog.vue";
import CreateCRM from "../components/Modals/CreateCRM.vue";
import SendProformaDialog from "../components/Modals/SendProformaDialog.vue";
import SectionHeadline from "../components/Typography/SectionHeadline.vue";
export default {
  components: {
    SectionHeadline,
    AddButton,
    AddLogDialog,
    HistoryDialog,
    CreateCRM,
    SendProformaDialog,
    DeleteDialog
  },
  data() {
    return {
      loadingProforma: {},
      search: "",
      tableKey: 0,
      timeout: null, // Add this line
      loading: false,
      editedItem: null,
      itemToDelete: null,
      createDialog: false,
      deleteDialog: false,
      sendProformaDialog: false,
      options: {},
      followUpFilter: "all",
      statusFilter: null,
      booleanItems: [
        {
          text: "Toate",
          value: "all",
        },
        {
          text: "Da",
          value: "true",
        },
        {
          text: "Nu",
          value: "false",
        },
      ],
      createdFilter: {
        show: false,
        date1: null,
        date2: null,
        filter: null,
      },
      updatedFilter: {
        show: false,
        date1: null,
        date2: null,
        filter: null,
      },

      logDialog: false,
      historyDialog: false,
      userHeaders: [
        {
          text: "",
          value: "actions",
        },
        {
          text: "Nume client",
          value: "name",
        },
        {
          text: "Telefon",
          value: "phone",
        },

        {
          text: "Email",
          value: "email",
        },
        {
          text: "CUI",
          value: "vatId",
        },

        {
          text: "Nume companie",
          value: "companyName",
        },

        {
          text: "Subiect",
          value: "subject",
        },

        {
          text: "Status",
          value: "status",
        },
        {
          text: "Data follow-up",
          value: "followUp",
        },
        {
          text: "Observatii",
          value: "observations",
        },

        {
          text: "Proforma / Trimisa",
          value: "proforma",
        },

        {
          text: "Data creare / actualizare",
          value: "createdAt",
        },

        {
          text: "User creare / actualizare",
          value: "createdBy",
        },
      ],
    };
  },
  watch: {
    options() {
      this.getData();
    },
    statusFilter() {
      this.getData();
    },
    followUpFilter() {
      this.getData();
    },
    search(newVal) {
      clearTimeout(this.timeout); // Clear the previous timeout
      this.timeout = setTimeout(() => {
        this.getData();
      }, 500);
    },
  },
  computed: {
    crm() {
      return this.$store.state.crm.crm;
    },
    totalCrm() {
      return this.$store.state.crm.totalCrm;
    },
    crmStatuses() {
      return this.$store.state.crm.crmStatuses.map((s) => s.status);
    },
  },
  methods: {
    showProforma(item) {
      this.loadingProforma[item._id] = true;
      this.tableKey++;
      this.$store.dispatch("generateProforma", {
        crmId: item._id,
        cb: () => {
          this.loadingProforma[item._id] = false;
          this.tableKey++;
          this.getData();
        },
      });
    },
    getRowClass(item) {
      if (item.status == "CONT CREAT") {
        return "goodMessage";
      }
      const now = new Date();
      const followUp = item.followUp ? new Date(item.followUp) : null;
      return followUp && followUp < now ? "errorMessage" : "";
    },
    saveItemStatus(item) {
      item.editedStatus = item.status;
      this.openLogDialog(item);
    },
    openSendProforma(item) {
      this.sendProformaDialog = true;
      this.editedUser = item;
    },
    closeSendProforma() {
      this.sendProformaDialog = false;
      this.editedUser = null;
    },
    openCreateDialog(item) {
      this.createDialog = true;
      this.editedItem = item;
    },
    closeCreateDialog() {
      this.createDialog = false;
      this.editedItem = null;
    },
    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.itemToDelete = null;
    },
    openLogDialog(item) {
      this.logDialog = true;
      this.editedUser = item;
    },
    openHistoryDialog(item) {
      this.historyDialog = true;
      this.editedUser = item;
    },
    closeLogDialog() {
      this.logDialog = false;
      this.editedUser = null;
    },
    closeHistoryDialog() {
      this.historyDialog = false;
      this.editedUser = null;
    },

    clearDateField(filterItem) {
      filterItem.date1 = null;
      filterItem.date2 = null;
      this.getData();
    },
    formatDateFilter(filterItem) {
      let filterValue = null;
      if (!filterItem.date1 || !filterItem.date2) {
        return;
      }
      filterValue = `${filterItem.date1} - ${filterItem.date2}`;
      filterItem.filter = filterValue;
      filterItem.show = false;
      this.getData();
    },
    getFilter() {
      let filter = {};
      if (
        this.createdFilter &&
        this.createdFilter.date1 &&
        this.createdFilter.date2
      ) {
        filter.createdAt = {
          date1: this.createdFilter.date1,
          date2: this.createdFilter.date2,
        };
      }
      if (
        this.updatedFilter &&
        this.updatedFilter.date1 &&
        this.updatedFilter.date2
      ) {
        filter.updatedAt = {
          date1: this.updatedFilter.date1,
          date2: this.updatedFilter.date2,
        };
      }

      if (this.statusFilter) {
        filter.status = this.statusFilter;
      }

      if (this.followUpFilter !== "all") {
        filter.followUp = this.followUpFilter;
      }

      return filter;
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    exportCRM() {
      let filter = this.getFilter();
      this.loading = true;
      this.$store.dispatch("exportCRM", {
        search: this.search,
        statusFilter: this.statusFilter,
        filter: filter,
        cb: (response) => {
          let fileName = "CRM RD.xlsx";
          this.loading = false;
          let fileUrl = window.URL.createObjectURL(response.data);
          let fileLink = document.createElement("a");
          fileLink.href = fileUrl;
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        },
      });
    },
    getData() {
      let filter = this.getFilter();
      this.loading = true;
      this.$store.dispatch("fetchCRM", {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        search: this.search,
        filter: filter,
        cb: () => {
          this.loading = false;
        },
      });
    },
    saveItem(item) {
      this.$store.dispatch("updateCRM", {
        model: item,
        cb: () => {
          this.getData();
        },
      });
    },
    deleteCRMItem(item) {
      this.$store.dispatch('deleteCRM', {
        model: item,
        cb: () => {
          this.getData();
        }
      });
    },
  },
  created() {
    this.$store.dispatch("fetchCRMStatuses", {});
    this.$store.dispatch("fetchCRMSubjects", {});
    // this.getData()
  },
};
</script>
<style scoped>
.filterWidth {
  width: 20%;
}

.searchWidth {
  width: 25%;
}

.fill-in {
  width: 150px;
}

.v-input :deep(.v-input__details) {
  display: none;
}

.v-input :deep(.v-input__control, .v-select__slot) {
  font-size: 14px;
}

.v-text-field :deep(.v-text-field__details) {
  display: none !important;
}

.fill-in.v-text-field__details {
  display: none;
}

.v-text-field :deep(.v-field__input) {
  font-size: 12px;
}

.v-input :deep(.v-field-label) {
  font-size: 12px;
}

.red-text {
  color: red !important;
}

.green-text {
  color: green !important;
}

.observations-text {
  white-space: nowrap; /* Textul rămâne pe un singur rând */
  overflow: hidden; /* Ascunde textul care depășește containerul */
  text-overflow: ellipsis; /* Adaugă "..." la sfârșitul textului tăiat */
}
</style>

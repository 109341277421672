import toastr from '../../toastr';
import axiosInstance from '../axiosWrapper';
export default {
  //===========================
  // STATE
  //===========================
  state: {
    companies: [],
    vatId: null,
    // totalCompanies: 0
  },
  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_COMPANIES(state, data) {
      state.companies = data
    },
    SET_VAT_ID(state, data) {
      state.vatId = data
    },
    UPDATE_COMPANY(state, company) {
      state.companies.map(c => {
        if (c._id == company._id) {
          c = {...company}
        }
      })
    },
    // SET_TOTAL_COMPANIES(state, totalCompanies) {
    //   state.totalCompanies = totalCompanies
    // },
  },
  //===========================
  // ACTIONS
  //===========================
  actions: {
    async createCompany({ commit }, formData) {
      let model = formData.data
      let cb = formData.cb
      let url = `${process.env.VUE_APP_API_URL}/companies`
      try {
        const res = await axiosInstance.post(
          url,
          // `${process.env.VUE_APP_API_URL}/companies/${userId}`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          toastr.success("Campul a fost adaugat!")
          cb()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchCompanies({ commit }, options) {

      let search = options ? options.search || '' : '';
      let filter = options && options.filter && options.filter != {} ? JSON.stringify(options.filter) : null

      let url = `${process.env.VUE_APP_API_URL}/companies`
      if (search && search.length) {
        url = `${url}?search=${search}`
      }

      if (filter && filter.length) {
        url = url.includes('search') ? `${url}&filter=${filter}` : `${url}?filter=${filter}`
      }
      
      try {
        // let url = `${process.env.VUE_APP_API_URL}/companies/partnersData/${userId}`
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_COMPANIES', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },

    async  fetchUserVatId({ commit }, options) {
      let url = `${process.env.VUE_APP_API_URL}/companies/vatId`
      try {
        // let url = `${process.env.VUE_APP_API_URL}/companies/partnersData/${userId}`
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_VAT_ID', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    
    async updateCompanyActive({ commit }, formData) {
      let companyId = formData.companyId
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/companies/switchActive/${companyId}`,
          {},
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async exportCompanies({ commit }, options) {
      let cb = options.cb
      let search = options.search || '' ;
      let filter =
        options.filter && options.filter != {}
          ? JSON.stringify(options.filter)
          : null;

      let queries = [];
      if (search && search.length) {
        queries.push(`search=${search}`);
      }
      if (filter) {
        queries.push(`filter=${filter}`);
      }

      let queryString = "";
      if (queries.length) {
        queryString = `?${queries.join("&")}`;
      }
      try {
        const res = await axiosInstance.get(
          `${process.env.VUE_APP_API_URL}/companies/export${queryString}`,
          {
            withCredentials: true,
            responseType: "blob",
          }
        );
        cb(res);
      } catch (error) {
        console.log(error);
        return this.error;
      }
    },
  },
};
